/*
 * @Author: Musa Tabitay
 * @Date: 2022-04-06 21:16:05
 * @LastEditTime: 2022-04-09 16:00:40
 */

import request from '@/utils/request'

// 登录
export const getToken = async data => {
  return request({
    method: 'POST',
    url: '/v2/token',
    data
  })
}

// 微信用户授权回调地址
export const wechatRedirect = '/h5/redirect'

// 获取用户信息
export const getUserInfo = () => {
  return request({
    method: 'GET',
    url: '/h5/getUserInfo'
  })
}

// 获取微信JSSDK
export const jssdk = () => {
  return request({
    method: 'GET',
    url: '/h5/jssdk',
    params: {
      url: location.href.split('#')[0]
    }
  })
}
