/*
 * @Author: Musa Tabitay
 * @Date: 2021-12-30 23:15:38
 * @LastEditTime: 2022-04-23 22:54:08
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import { getCookie } from '@/utils/util'
import { wechatRedirect } from '@/api/user'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import('@/views/login')
  // },
  // {
  //   path: '/',
  //   // name: 'layout', // 如果父路由有默认子路由，那它的name没有意义
  //   component: () => import('@/views/layout'),
  //   children: [
  //     {
  //       path: '', // 默认子路由，只能有一个
  //       name: 'home',
  //       component: () => import('@/views/home'),
  //       // 需要登录后才能访问的页面
  //       meta: { requiresAuth: true }
  //     }
  //     // {
  //     //   path: 'my',
  //     //   name: 'my',
  //     //   component: () => import('@/views/my'),
  //     //   // 需要登录后才能访问的页面
  //     //   meta: { requiresAuth: true }
  //     // }
  //   ]
  // },
  // {
  //   path: '/list/:id',
  //   name: 'list',
  //   component: () => import('@/views/article/list'),
  //   // 将路由动态参数映射到组件的 props 中，更推荐这种做法
  //   props: true,
  //   // 需要登录后才能访问的页面
  //   meta: { requiresAuth: true }
  // },
  {
    path: '/article/:artId',
    name: 'article',
    component: () => import('@/views/article'),
    props: true,
    // 需要登录后才能访问的页面
    meta: { requiresAuth: true }
  },
  {
    path: '/firstEnter',
    name: 'firstEnter',
    component: () => import('@/views/firstEnter')
  }
]

const router = new VueRouter({
  routes
})

// 前置导航守卫
router.beforeEach((to, from, next) => {
  const openId = getCookie('openId')
  // 检查用户是否授权过
  if (to.meta.requiresAuth && !openId) {
    location.href = `${wechatRedirect}?url=${encodeURIComponent(location.href)}&scope=snsapi_userinfo`
  }
  next()
})

export default router
