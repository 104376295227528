/*
 * @Author: Musa Tabitay
 * @Date: 2022-04-06 21:16:05
 * @LastEditTime: 2022-04-12 23:17:35
 */
/**
 * 封装 axios 请求模块
 */
import axios from 'axios'
const CryptoJS = require('crypto-js')
const secretKey = 'rhDaeJnL8x'
const instance = axios.create()
const Base64 = require('js-base64').Base64

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  // 将token给到一个前后台约定好的key中，作为请求发送
  let userInfo = localStorage.getItem('userInfo')
  userInfo = userInfo && JSON.parse(userInfo)
  if (userInfo?.token) {
    const base64 = Base64.encode(userInfo.token + ':')
    config.headers.Authorization = `Basic ${base64}`
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

instance.interceptors.response.use(res => {
  return handleResult(res.data)
}, err => {
  return Promise.reject(err)
})

// 解密数据
function handleResult (res) {
  const bytes = CryptoJS.AES.decrypt(res, secretKey)
  const result = bytes.toString(CryptoJS.enc.Utf8)
  const decryptRes = JSON.parse(result)
  // console.log(decryptRes)
  return decryptRes
}

// 请求工具函数
export default instance
