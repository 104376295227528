import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
// 加载vant核心组件库
import Vant, { Dialog } from 'vant'
// 加载vant全局样式
import 'vant/lib/index.css'
// 加载全局样式
import './styles/index.less'
// 加载动态设置 rem 基准值
import 'amfe-flexible'

import VueCookies from 'vue-cookies'

Vue.use(Dialog)
Vue.use(VueAxios, axios)

Vue.use(VueCookies)

// 注册使用Vant组件库
Vue.use(Vant)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
